.brand-switcher {
  button {
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: $openbroker-primary;
    width: 40px;
    height: 40px;
    font-weight: bold;
  }
}

.brand-list {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .item-container {
    width: 50%;
    display: flex;
    justify-content: center;
  }
}

.brand-list-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  height: 80px;
  background-image: linear-gradient(
                  -45deg, #4695ff, #1970e2);

  h5 {
    margin-bottom: 0.25rem;
    color: white;
  }

  p {
    margin-top: 0;
    color: white;
  }
}

.brand-item {
  width: 92px;
  text-align: center;

  h6 {
    margin-top: 0;
  }

  img {
    padding: 0.25rem;
    background-color: #fff;
    border: 3px solid #fff;
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
    box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
    margin-bottom: 24px;
  }
}