$openBrokerBlue: #4447a4;
$openBrokerBlueLight: #efeffb;
$openBrokerCyan: #06d6a0;

$main-blue: $openBrokerBlue;
$main-blue-80: lighten($main-blue, 20%);
$main-blue-60: lighten($main-blue, 40%);
$main-blue-50: lighten($main-blue, 50%);
$main-blue-40: lighten($main-blue, 60%);
$main-blue-20: lighten($main-blue, 80%);
$main-blue-darker: darken($main-blue, 20%);
$main-green: $openBrokerCyan;
$main-green-80: lighten($main-green, 20%);
$main-green-60: lighten($main-green, 40%);
$main-green-40: lighten($main-green, 60%);
$main-green-20: lighten($main-blue, 80%);
$main-orange: #f59331;
$main-orange-50: lighten($main-orange, 40%);
$main-orange-20: lighten($main-orange, 20%);
$main-orange-30: lighten($main-orange, 30%);
$main-red: #ff3232;
$main-red-20: lighten($main-red, 50%);
$main-yellow: #ffe51f;
$white: #fff;
$smoke-gray: #fafafa;
$gray: #9b9b9b;
$light-gray: #e8e8e8;
$medium-gray: #aaa;

$message-incoming: #f3f3f3;
$message-outgoing: rgb(233, 238, 239);
$system-message-color: #967f68;
$system-message-border-color: #e8cbad;
$system-message-background-color: rgba(245, 147, 49, .05);
$evaluation-message-background-color: #fdfcf3;
$evaluation-message-color: #716502;

$secondary-btn-color: #E4EBEF;

//$default-color: #0a0a0a;
$default-color: #5e6e82;

$facebook-color: #3b5998;
$google-color: rgb(52, 109, 240);

$blue-navy: #404A54;
$dark-gray: rgba(0, 0, 0, .6);
$red-peps: #ff3232;
$dark-blue: #1a252f;

//$body-background-color-provider: #F5F7FA;
$body-background-color-provider: #edf2f9;
$main-menu-background-color-provider: #4447a4;
$notification-bullet-background-color: $red-peps;
$provider-color: $blue-navy;
$directory-background: #FCFCFD;
$directory-border: #E3E8EC;


$openbroker-primary: #5555FF;
$openbroker-primary-light: #F0FFFB;
$openbroker-green-light: #54ca633d;
$openbroker-secondary: $openBrokerBlue;
$openbroker-secondary-light: $openBrokerBlueLight;
$openbroker-gray: $gray;
$openbroker-gray-light: $light-gray;
$openbroker-warning: $main-orange;
$openbroker-error: $main-red;
$openbroker-default-text: $default-color;
